.sign_cont > div {
    color: #9A9FB5;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.info_container {
    font-weight: 300;
    color: #485171;
    font-size: 14px;
}

.not_you {
    color: #F36D6B;
}

.link_container > button {
    margin: 0px 10px;
    background-color: #fff;
    border: none;
}
.link_container > button:hover{
    cursor: pointer;
}

.invitation_info_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.invitation_info_container > img {
    width: 100px;
    height: 100px;
}

.invitation_info_container > span {
    margin-top: 25px;
    font-size: 18px;
    width: 361px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .info_container {
        width: 361px;
    }
}

@media screen and (max-width: 375px) {
    .info_container {
        width: 300px;
    }
    
    .invitation_info_container > span {
        width: auto;
    }
}

@media screen and (max-width: 320px) {
    .info_container {
        width: 280px;
    }
    
}
