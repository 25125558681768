.terminal_log_cont {
    margin-top: 20px;
    margin-bottom: 31px;
    width: 100%;
    max-height: 580px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
}

.terminal_log_head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
    background: #158AF0;
    border-radius: 10px 10px 0px 0px;
}

.terminal_log_head img {
    height: 30px;
    margin-left: 29px;
}

.terminal_head_text {
    margin-left: 12px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
}

.logs-terminal-body {
    overflow: auto;
    max-height: 518px;
}