.terminal_cont {
    display: flex;
    max-width: 100%;

    height: 600px;
}

.terminal_area {
    flex: 1;
    width: calc(100% - 20px);
    height: 590px;
    background: #000000;
    border-radius: 10px;
    color: rgba(255, 255, 255, 1);
    padding: 15px;
}

.xterm-screen {
    width: auto !important;
}

.xterm-rows > div {
    width: auto !important;
}

.xterm-selection {
    color: white;
    background-color: #0072c6;
}