.script_plate_container {
    margin: 15px 13px 0 0;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 713px;
    max-height: 134px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.script_plate_container > img {
    margin: 20px 21px 17px 21px;
}

.script_text_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
}

.script_text_left {
    display: flex;
    flex-direction: column;
}

.script_text_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    margin: 0 20px 20px 0;
}

.script_title {
    color: #485171;
    font-family: 'Urbanist';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.script_description {
    min-width: 150px;
    max-width: 430px;
    color: #7c85a4;
    font-family: 'Urbanist';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.script_updated {
    /* position: absolute;
  top:21px;
  right: 27px; */
    color: #7c85a4;
    text-align: right;
    font-family: 'Urbanist';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.script_img_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.script_img_container img:last-child {
    margin-left: 10px;
}

.script_status_label {
    position: absolute;
    display: flex;
    right: 107px;
    bottom: 0px;
    width: 120px;
    height: 31px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    border-radius: 10px 10px 0px 0px;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.script_status_add {
    background: #04ca7e;
    color: #fff;
    cursor: pointer;
}

.script_status_is_added {
    color: #9A9FB5;
    border: 1px solid #9A9FB5;
    border-bottom: none;
}

.lock_img {
    position: relative;
}

.hover_lock_mess {
    display: none;
    position: absolute;
    left: -75px;
    z-index: 10;
}

.message_lock_container {
    position: relative;
}

.message_lock_container_text {
    position: absolute;
    top: 10px;
    left: 30px;
    color: #7c85a4;
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.lock_img_content:hover + .hover_lock_mess {
    display: flex;
}




.circle_img{
    position: relative;
    margin-left: 10px;
}

.hover_circle_mess {
    display: none;
    position: absolute;
    left: -105px;
    z-index: 10;
}

.message_circle_container {
    position: relative;
}

.message_circle_container_text {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #7c85a4;
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.circle_img_content:hover + .hover_circle_mess {
    display: flex;
}

@media screen and (max-width: 723px) {
    .script_plate_container {
        margin-right: 0;
    }
}
@media screen and (max-width: 589px) {
    .script_description {
        min-width: 0;
    }
}