@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

#root {
    overflow-y: hidden;
}

.small-loader-white {
    height: 44px;
}

.auth-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row-reverse;
    /* width: 1196px;
    height: 550px; */
    font-family: 'Urbanist';
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.first_section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 768px;
    /* height: 550px; */
    background-color: #158AF0;
    border-radius: 10px;
}

.first_section > img {
    overflow: hidden;
    object-fit: cover;
    /* width: 100%; */
    height: 550px;
}

.second_section {
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 34px 31px 34px 35px;
}

.second_section_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text_cont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.text_cont > div {
    color:#485171;
}

.sign_cont > h2 {
    margin: 0px;
    margin-bottom: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.sign_cont > div {
    color: #9A9FB5;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

#form {
    margin: 61px 0 10px 0;
    flex: 1;
    display: grid;
    flex-direction: column;
}

#form > .auth-btn-group {
    align-self: flex-end;
}

#form > .auth-btn-group > button {
    padding: 0px 6px;
    background-color: #158AF0;
    width: 361px;
    height: 45px;
    color:#fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
#form > .auth-btn-group > button:hover{
    cursor: pointer;
}

.input_container > .input_head {
    margin-bottom: 2px;
}
.input_container {
    margin-bottom: 17px;
}

.input_container > div {
    color:#485171;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.img_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 20px;
    border-radius: 10px 0px 0px 10px;
    background-color: #F8F8F8;
}

.img_container::after {
    content: "";
    position: relative;
    width: 1px;
    height: 35px;
    margin-left: 20px;
    background-color: #D9D9D9;
}

.img_container > img {
    width: 24px;
    height: 24px;
}

.custom_input {
    display: flex;
    flex-direction: row;
}

.custom_input > input {
    font-family: 'Inter';
    font-size: 15px;
    line-height: 18px;
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    height: 55px;
    color: #485171;
    background-color: #F8F8F8;
    border-radius: 0 10px 10px 0;
    outline: none;
    border: none;
}

.custom_input > input:focus{
    outline: none;
}

.link_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 29px 0 0 0;
}

.link_container > button {
    margin: 0px 10px;
    background-color: #fff;
    border: none;
}

.link_container > button:hover {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .first_section {
        width: auto;
    }

    .auth-container {
        width: 90%;
    }
}

@media screen and (max-width: 960px) {
    .auth-container {
        justify-content: center;
        height: 550px;
    }

    .first_section {
        display: none;
    }
}

@media screen and (max-width: 660px) {
    .second_section {
        flex: 1;
    }

    #form > .auth-btn-group {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    #form > .auth-btn-group > button {
        width: auto;
    }
}

@media screen and (max-width: 490px) {
    .auth-container {
        zoom: 0.9;
    }
}