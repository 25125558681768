.modal {
    /* display: flex; */
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    backdrop-filter: blur(3px);
    transition-property: opacity;
    transition-duration: 0.5s;
    overflow-y: auto;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: white;
    max-width: 1093px;
    max-height: 85%;
    display: flex;
    flex-direction: column;
}

.popup_cont {
    min-width: 728px;
    max-height: 610px;
}

.popup_name_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    border-radius: 10px 10px 0 0;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    z-index:30px;

}

.popup_left_name_cont {
    display: flex;
    align-items: center;
    margin-left: 24px;
}

.popup_left_name_cont > img {
    width: 40px;
}

.popup_name_text {
    margin-left: 20px;
}

.popup_right_name_cont {
    display: flex;
    align-items: center;
    margin-right: 30px;

}

.popup_right_name_cont > img {
    width: 25px;
}

.maximize_popup {
    cursor: pointer;
}

.img_close_popup {
    cursor: pointer;
    margin-left: 16px;
}