.list_device_cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;
    width: calc(100% - 40px);
    background-color: #fff;
    border-radius: 10px;
}


.list_name_row {
    margin-bottom: 14px;
    display: grid;
    grid-template-columns: 0.7fr repeat(3, 2fr) 3fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: calc(100% - 80px);
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #485171;
}

.list_name_row>.div1 {
    text-align: center;
}
.list_name_row>.div2 {
    padding-left: 35px;
}
.list_name_row>.div3 {
    padding-left: 30px;
}
.list_name_row>.div4 {
    padding-left: 25px;
}
.list_name_row>.div5 {
    padding-left: 30px;
}

.animation_devices_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
}

.animation_devices_loading {
    width: 80px;
    height: 80px;
    animation: 2s infinite linear load;
}

@media screen and (max-width: 1100px) {
    .list_device_cont {
        width: calc(100% - 20px);
    }
}