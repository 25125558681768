.claster_container {
    width: calc(100% - 20px);
    min-height: 77px;
    max-height: 700px;
    margin-bottom: 14px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.claster_min {
    margin: 34px 0px 10px 34px;
    max-height: 70px;
    width: 100%;
    transition: 0.5s;
}

.claster_max {
    margin: 34px 0px 0px 34px;
    padding-bottom: 24px;
    max-height: 700px;
    width: 100%;
    transition: 0.5s;
}

.claster_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 65px);

}

.claster_info_button {
    display: flex;
    align-items: center;
    flex-direction: row;
}


.claster_check_img {
    margin-right: 20px;
}

.claster_set_img {
    margin-right: 26px;
}

.claster_min .claster_fill {
    opacity: 0;
    transition: 0.5s;
}

.claster_max .claster_fill {
    opacity: 1;
    transition: 0.5s;
}



.claster_min .chevron {
    transition: 0.5s;
}

.claster_max .chevron {
    transform: rotate(180deg);
    transition: 0.5s;
}


.claster_name_column {
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr) 3fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;;
    width: calc(100% - 70px);
    transition: 0.5px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.claster_name_column div:nth-child(1) {
    margin-left: 24px;
}


/* .claster_name_column div:nth-child(2) {
    margin-right: 25px;
    margin-left: 15px;
}
.claster_name_column div:nth-child(3) {
    margin-right: 25px;
    margin-left: 25px;
} */


.claster_name_column>div {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #485171;
}

.claster_fill_log {
    max-height: 525px;
    overflow: auto;
}

.claster_info_button img {
    cursor: pointer;
}