.add_script_pop_cont {
    padding: 37px;
    padding-top: 0;
}

.script_outline_area {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #485171;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.script_textarea_outline {
    height: 233px;
    resize: none;
    margin-top: 6px;
    padding-top: 16px;
    padding-left: 25px;
    background: #f8f8f8;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    overflow: auto;
    color: #9a9fb5;
    font-family: 'Urbanist';
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.script_textarea_outline:focus {
    outline: none;
}

.add_script_button_popup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
}
.add_script_button {
    display: flex;
    /* width: 200px; */
    height: 45px;
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 6px;
    background: #04ca7e;
    border: none;
    color: #fff;
    font-size: 20px;
    font-family: 'Urbanist';
    font-weight: 600;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.2s;
}
.add_script_button:disabled {
    background: #83ebc3;
    cursor: default;
    transition-property: background;
    transition-duration: 0.2s;
}

@media screen and (max-height: 900px) {
    .add_script_areas_overflow {
        overflow: auto;
        max-height: 500px;
    }
}
