@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Urbanist';
    background-color: #f2f3f7;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

aside {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    margin-top: -96px;
    background-color: #ffffff;
    border-right: 2px solid #f2f3f7;
    transition: 0.5s;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1000;
}

.aside-menu > ul {
    margin: 0 auto;
    padding: 0;
}

.aside-menu > ul > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 41px;
    margin: 15px 0 0 0;
    cursor: pointer;
    transition: 0.5s;
    white-space: nowrap;
    text-overflow: clip;
}
.aside-menu > ul > .scripts_link_container > li {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    height: 41px;
    cursor: pointer;
    transition: 0.5s;
    white-space: nowrap;
    text-overflow: clip;
    width: 50px;
}

.scripts_link_container > li:nth-child(2) {
    margin-top: 20px;
}
.scripts_link_container > li:nth-child(3) {
    margin-top: 20px;
}

.menu-min {
    width: 70px;
}

.menu-max {
    width: 180px;
}

.burger_container {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
    margin-right: 10px;
    transition: 0.5s;
}

.burger_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 10px 0 0 0;
    padding: 0 auto;
    width: 50px;
    height: 50px;
    border: none;
}

.burger_menu:hover {
    cursor: pointer;
}

.burger_img {
    width: 22px;
    height: 22px;
}

.navigation_link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin: 5px 10px;
    text-decoration: none;
    overflow: hidden;
    transition-property: padding;
    transition-duration: 0.5s;
}

.link_active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin: 5px 10px;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition-property: padding;
    transition-duration: 0.5s;
}

.market_link_active{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
    background-color: #FFF;
    overflow: hidden;
    transition-property: padding;
    transition-duration: 0.5s;
}
.scripts_active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    overflow: hidden;
    transition-property: padding;
    transition-duration: 0.5s;
}

.scripts_link_container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 15px;
    width: 50px;
    height: 180px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #eceff1;
}

.menu-max  .scripts_link_container {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
}
.menu-max .aside-menu > ul > .scripts_link_container > li  {
    width: auto;
}

.menu-max .aside-menu .navigation_link {
    padding-left: 10px;
    justify-content: flex-start;
}

.link_active > span {
    color: #158af0;
}

.link_active > svg > path {
    fill: #158af0;
}
.market_link_active > span {
    color: #158af0;
}

.market_link_active > svg > path {
    fill: #158af0;
}
.scripts_active > span {
    color: #158af0;
}

.scripts_active > svg > path {
    stroke: #158af0;
}

.navigation_link:hover > svg > path {
    fill: #158af0;
    transition: 1s;
}
.nav_scripts:hover > svg > path {
    stroke: #158af0;
    fill: none;
    transition: 1s;
}

.navigation_link:hover > span {
    color: #158af0;
}


.menu-max .aside-menu .link_active {
    padding-left: 10px;
    justify-content: flex-start;
}
.menu-max .aside-menu .market_link_active {
    padding-left: 10px;
    justify-content: flex-start;
}
.menu-text {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #485171;
}

.menu-min .menu-text {
    transition-property: opacity, width;
    transition: 0.5s;
    opacity: 0;
    width: 0%;
}

.menu-max .menu-text {
    display: flex;
    align-items: center;
    width: 100px;
    height: 50px;
    margin-left: 10px;
    transition: 1s;
    transition-property: width, opacity;
    opacity: 1;
    transition: 0.5s;
}

.menu-min .link_active {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-min .navigation_link {
    display: flex;
    align-items: center;
    justify-content: center;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 77px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1000;
}

.logo {
    margin-left: 32px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #485171;
}

.logo_adaptiv {
    display: none;
}

.header_info_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

/* .header_info_container > div {
    margin: 0 10px;
} */

.user_information_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.user_name {
    margin-left: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #485171;
}

.header_min {
    margin-left: 90px;
    margin-top: 19px;
    transition: 0.5s;
}

.header_max {
    margin-left: 200px;
    margin-top: 19px;
    transition: 0.5s;
}

.section_min {
    display: flex;
    margin: 14px 20px 0 90px;
    transition: 0.5s;
}

.section_max {
    display: flex;
    margin: 14px 20px 0 200px;
    transition: 0.5s;
}

.menu-max svg {
    margin: 0 auto;
    padding: 0 auto;
}

.back_blur {
    display: none;
    z-index: 1000;
}

.logout_popup {
    position: absolute;
    top: 96px;
    right: 20px;
    z-index: 9999;
}

.small_square {
    position: absolute;
    width: 9.96px;
    height: 9.96px;
    background: #ffffff;
    left: 154px;
    top: 5px;
    transform: rotate(-45deg);
    z-index: -1;
}

.window_cont {
    width: 197px;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #eeeff4;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 3;
    overflow: hidden;
}

.logout_popup_open {
    opacity: 1;
    transition: 0.5s;
}
.logout_popup_close {
    opacity: 0;
    transition: 0.5s;
}

.logout_popup_open > .window_cont {
    height: 150px;
    transition-duration: 0.5s;
}

.logout_popup_close > .window_cont {
    height: 0px;
    transition-duration: 0.5s;
}

.logout_popup_name {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: self-end;

    margin: 12px 12px 0 0;
}

.logout_popup_name_top {
    display: flex;
    justify-content: end;
}
.logout_popup_name_org {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    line-height: 11px;
    color: #9A9FB5;
}
.logout_popup_name_top div {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #485171;
    margin-left: 5px;
}

.logout_popup_center {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.logout_popup_center_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.log_line {
    width: 24px;
    height: 1px;
    background: #D9D9D9;
    margin: 5px 0;
}

.logout_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
}

.logout_button div {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    text-align: center;

    color: #485171;
}
.logout_button img {
    margin-left: 5px;
}

.logout_popup_button {
    cursor: pointer;
    font-size: 13px;
    font-family: "Urbanist";
    font-weight: 500;
    color: #485171;
    margin-bottom: 6px;
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .aside-menu {
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
        top: 85px;
        transition: 0.5s;
    }

    .menu-min .link_active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 50px;
        overflow: visible;
    }

    .back_blur {
        display: flex;
        position: fixed;
        width: calc(100vw);
        height: 99px;
        margin-top: -100px;
        /* filter: blur(5px); */
        opacity: 0.8;
        background-color: #f2f3f7;
    }

    aside {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -90px;
        margin-left: 20px;
        height: 76px;
        width: 100px;
        border-radius: 10px;
        border: 1px solid #f2f3f7;
    }

    .menu-min {
        width: 60px;
    }

    .menu-max {
        width: 60px;
    }

    .max .aside-menu {
        display: flex;
        position: absolute;
        margin-left: 110px;
        top: 85px;
        width: calc(100vw + 100vw);
        height: 100vh;
        background-color: #ffffff;
        transition: 0.5s;
        opacity: 1;
    }

    .burger_container {
    }

    .burger_menu {
        margin: 0;
    }

    .link_active {
        margin-top: 20px;
    }

    .nav_scripts {
        margin: 0 !important;
    }
    .nav_scripts > .menu-text{
        margin: 0 !important;
    }

    .navigation_link {
        margin-top: 20px;
    }

    header {
        border: 1px solid #f2f3f7;
        transition: none;
        width: calc(100vw - 110px);
    }

    .max header {
        pointer-events: none;
    }

    .ret {
        background: rgba(0, 0, 0, 0.8);
    }

    .header_min {
        margin-left: 90px;
        margin-top: -90px;
        position: fixed;
        transition: none;
    }

    .header_max {
        margin-left: 90px;
        margin-top: -90px;
        position: fixed;
        transition: none;
    }

    .section_min {
        display: flex;
        margin: 100px 20px 0 20px;
        transition: 1s;
    }

    .section_max {
        display: flex;
        margin: 100px 20px 0 20px;
        transition: 1s;
    }
}

/* @media screen and (max-width: 768px) {
    .menu-max .section_max {
        opacity: 0;
    }

} */

@media screen and (max-width: 480px) {
    .header_min {
        width: calc(100vw - 110px);
    }

    .header_max {
        width: calc(100vw - 110px);
    }

    .logo {
        display: none;
    }

    .logo_adaptiv {
        display: flex;
        margin-left: 32px;
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #485171;
    }
}

@media screen and (max-width: 425px) {
    .back_blur {
        width: calc(100vw);
    }

    .user_name {
        display: none;
    }

    .logo_adaptiv {
        margin: 0 15px 0 15px;
    }

    .header_min {
        width: calc(100vw - 110px);
    }

    .header_max {
        width: calc(100vw - 110px);
    }
}

@media screen and (max-width: 375px) {
    .header_min {
        width: calc(100vw - 110px);
    }

    .header_max {
        width: calc(100vw - 110px);
    }
}

@media screen and (max-width: 325px) {
    .logo_adaptiv {
        margin: 0 0 0 5px;
    }

    .back_blur {
        width: calc(100vw + 29px);
    }
}
