body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.all_page_title {
  color: #485171;
  font-family: Urbanist;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 28px;
}

.input_device_name {
  display: flex;
  width: calc(100% - 27px);
  height: 58px;
  margin-top: 8px;
  padding-left: 25px;
  background: #F8F8F8;
  border-radius: 10px;
  border: none;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #7C85A4;
}

.input_device_name:focus {
  outline: none;
}

.invite_input {
  width: calc(100% - 40px);
  height: 56px;
  padding: 0 20px;
  margin-top: 10.5px;
  background: #f8f8f8;
  border-radius: 10px;
  border: none;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #7c85a4;
}