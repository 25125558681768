.statistics_menu_cont {
    display: flex;
    flex-direction: column;
    height: 197px;
    width: 100%;
    border-radius: 10px;
    background-color: #ffffff;
}


.statistics_inner_cont {
    display: flex;
    flex-direction: row;
    width: 190px;
    min-width: 120px;
    height: 71px;
    border-radius: 10px;
    background-color: #F8F8F8;
}

.statistics_first_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 20px 20px 7.5px 20px;
}

.statistics_second_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 7.5px 20px 20px 20px;
}


.updates_img {
    height: 34px;
    width: 34px;
    margin: 19px 11px 18px 13px;
}

.restart_img {
    height: 34px;
    width: 34px;
    margin: 19px 11px 18px 13px;
}

.wifi_off_img {
    height: 34px;
    width: 34px;
    margin: 16px 8px 15px 13px;
}

.statistics_inner_text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 14px 10px 14px 0;
}

.statistics_text_title {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 12px;
    color: #485171;
}

.statistics_text_desc {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(72, 81, 113, 1);
}

.desc_text_in {
    margin: 0 3px;
    color: rgba(154, 159, 181, 1);
}

.statistics_text_desc_big {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #485171;
}

@media screen and (max-width: 1100px) {
    .statistics_menu_cont {
        margin-top: 10px;
    }
}