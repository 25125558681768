.filter_show_available {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 37px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #f8f8f8;
}

.custom_checkbox_container {
    padding-left: 20px;
}

.custom_checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom_checkbox_label {
    margin-left: -10px;
}

.custom_checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #9a9fb5;
    cursor: pointer;
}

.custom_checkbox + label::before {
    content: '';
    display: inline-block;
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    flex-grow: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70% 70%;
    background-image: url('/public/img/custom.svg');
    cursor: pointer;
}

.custom_checkbox:checked + label::before {
    border-color: rgba(72, 81, 113, 1);
    background-image: url('/public/img/custom_check.svg');
}

.filters_container {
    width: 100%;
    min-height: 40px;
    max-height: 200px;
    margin: 10px 0 8px 0;
    background-color: #f8f8f8;
    border-radius: 10px;
    overflow: hidden;
}

.filters_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 5px 0 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    cursor: pointer;
    transition: 0.5s;
}
.filters_info > div {
    color: #485171;
    font-family: "Urbanist";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.filters_min {
    height: 37px;
    transition: 0.5s;
}

.filters_max {
    height: 200px;
    transition: 0.5s;
}

.filters_min .chevron {
    transition: 0.5s;
}

.filters_max .chevron {
    transform: rotate(180deg);
    transition: 0.5s;
}

.filters_min .filters_inner {
    transition: 0.5s;
    opacity: 0;
    overflow: hidden;
}

.filters_max .filters_inner {
    opacity: 1;
    height: 160px;
    transition: 0.5s;
    overflow: auto;
}
