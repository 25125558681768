.add_script_button_cont {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-left: 15px;
    /* width: 200px; */
    height: 49px;
    background: #ffffff;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;
}

.add_script_button_cont img {
    margin: 11px 8px 10px 9px;
}
.add_script_button_cont div {
    font-family: 'Urbanist';
    margin-right: 26px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #485171;
}

