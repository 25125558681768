.dayreview_add_script_cont {
    display: flex;
    flex-direction: column;
    padding: 40px;
}
.dayreview_add_script_input {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.blue_radio_label {
    color: #158af0 !important;
    font-family: Urbanist;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.search_select_script_cont {
    display: flex;
    flex-direction: column;
    position: relative;
}

.search_select_script_cont > label {
    position: absolute;
    left: 14px;
    top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.search_select_script_cont > input {
    height: 39.067px;
    padding: 0px 12px 0px 30px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #d9d9d9;
    background: #f5f6fb;
    color: #485171;
    font-family: Urbanist;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    appearance: none;
    background-image: url(/public/img/select_arrow.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer;
}
.search_select_script_cont > input::before {
    background-image: url(/public/img/select_arrow.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.search_select_script_cont > input:focus {
    outline: none;
}

.input_element_li {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 0 12px;
    /* border: 1px solid #d9d9d9; */
    background: #f8f8f8;
    color: #485171;
    cursor: pointer;
}

.input_element_li:hover {
    background: #fff;
}

/* .search_select_script_cont > select > option {
    height: 39.067px;
    padding: 10px 12px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #d9d9d9;
    background: #f5f6fb;
    width: 100%;
    color: #485171;
} */

.search_results {
    position: absolute;
    left: 0;
    top: 40px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 2px);
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 0 0 5px 5px;
    max-height: 300px;
    overflow: auto;
    z-index: 10;
}

.search_results > div:last-child {
    border-radius: 0 0 5px 5px;
}

.valid_time_text {
    margin-top: 10px;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #f36d6b;
}

.input_device_name {
    transition-duration: 0.2s;
}