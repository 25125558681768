.marketplace_scripts_container {
    /* display: flex; */
    /* flex-direction: column; */
    margin-top: 64px;
    width: 100%;
}

.marketplace_scripts_button_container {
    display: flex;
    flex-direction: row;
}

.marketplace_scripts_content {
    /* width: 100%; */
    /* display: grid; 
    grid-template-columns: 10fr 2fr; 
    display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); */
    /* grid-gap: 10px; */
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.marketplace_scripts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 2fr);
    /* display: flex;
    flex-direction: row;   
    align-items: flex-start;
    justify-content: flex-start; */
    /* max-width: 1790px; */
    /* height: 600px; */
    flex: 5;
    flex-wrap: wrap;
}
.marketplace_filters {
    /* display: flex;
    flex: 1.2; */
    margin: 15px 0 0 15px;
    height: 478px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.marketplace_filters_cont {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-width: 290px;
}
.marketplace_filters_inner {
    margin: 12px;
    overflow: auto;
    height: 378px;
}

.no_script_text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #485171;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.loader_grid_cont {
    display: grid;
    grid-row: 2;
    grid-column: 2;
}

@media screen and (max-width: 2760px) {
    .marketplace_scripts { 
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (max-width: 1953px) {
    .marketplace_scripts { 
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 1420px) {
    .marketplace_filters_cont {
        min-width: 250px;
    }
}
@media screen and (max-width: 1388px) {
    .marketplace_scripts {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 723px) {
    .marketplace_scripts_content {
        grid-template-columns: 1fr;
    }
    .marketplace_scripts {
        grid-row: 2;
    }
    .marketplace_filters {
        margin-left: 0;
    }
}
@media screen and (max-width: 511px) {
    .marketplace_scripts_button_container {
        flex-direction: column;
    }
}
