.kanban_section_cont {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    margin-right: 30px;
}
.kanban_section_cont:last-child {
    margin: 0;
}

.kanban_section_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 17px;
    height: 80px;
    border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
color: #485171;
    text-align: center;
    font-family: Urbanist;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    box-sizing: border-box;
}

