.add_devices_button_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 220px;
    background-color: white;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 50px;
}

.add_devices_button_cont:hover {
    cursor: pointer;
}

.devices_button_img {
    width: 30px;
    height: 30px;
    margin-left: 15px;
}

.add_devices_text {
    margin-left: 5px;
    margin-right: 14px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #485171;

}

@media screen and (max-width: 460px) {
    .add_devices_button_cont {
        margin: 10px 0 0 0;
    }
}