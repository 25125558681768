.progress_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress_title {
    margin-top: 17px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #485171;
}

.progress_tasks_container {
    display: flex;
    margin-top: 22px;
}

.progress_tasks_container>img {
    margin-right: 13px;
}

.progress_tasks_text {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #485171;
}

@media screen and (max-width: 768px) {
    .img_progress {
        width: 100%;
        height: 100%;
    }
}


.first-figure {
    height: 200px;
    width: 200px;
    background-color: #04CA7E;
    clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
}