.devices_container {
    width: 100%;
    min-height: 77px;
    max-height: 400px;
    margin-bottom: 8px;
    background-color: #F8F8F8;
    border-radius: 10px;
    overflow: hidden;
}


.devices_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 65px);
    margin: 25px 0 35px 34px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    cursor: pointer;
    transition: 0.5s;
}

.devices_min {
    height: 77px;
}

.devices_max .chevron {
    transform: rotate(180deg);
    transition: 0.5s;
}

.devices_min .chevron {
    transition: 0.5s;
}


.first_info_cont {
    display: flex;
    flex-direction: row;
}

.devices_min {
    height: 52px;
}

.devices_max {
    height: 216px;
}

.devices_text {
    margin-left: 28px;
}

.devices_text_s {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #9A9FB5;
}

.devices_text_h {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #485171;
}

.devices_fill {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 25px 54px 0px 54px;
    transition: 0.4s;
    cursor: pointer;
}

.devices_min .devices_fill {
    opacity: 0;
    transition: 0.5s;
}

.devices_max .devices_fill {
    opacity: 1;
    transition: 0.5s;
}

.devices_fill_info {
    display: flex;
    margin-bottom: 3px;
}

.devices_fill_info_first {
    width: 36px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #485171;
}

.devices_fill_info_second {
    margin-left: 9px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #9A9FB5;
}


@media screen and (max-width: 425px) {

    .devices_info {
        margin: 0;
        margin-left: 15px;
        margin-top: 25px;
        width: calc(100% - 30px);
    }

    .devices_max {
        height: 180px;
    }
}

@media screen and (max-width: 375px) {

    .devices_min {
        height: 70px;
    }
    .devices_max {
        height: 200px;
    }

    .devices_fill {
        margin: 25px 10px 10px 30px;
    }
}
@media screen and (max-width: 320px) {

    .devices_min {
        height: 70px;
    }
    .devices_max {
        height: 250px;
    }

    .devices_fill {
        margin: 15px 10px 10px 20px;
    }

    .devices_fill_info {
        flex-direction: column;
    }

    .devices_text {
        margin-left: 8px;
    }

    .devices_fill_info_second {
        margin: 0;
    }
}