.list_task_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background: #f8f8f8;
    padding: 20px 72px 20px 28px;
}

.list_task_left {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.list_task_title {
    color: #7c85a4;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 34px;
}

.list_task_sedot {
    border-radius: 25px;
    background: #158af0;
    padding: 5px 10px;
    color: #fff;
    text-align: center;
    font-family: Urbanist;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.list_task_freelance {
    border-radius: 25px;
    background: #04ca7e;
    padding: 5px 15px;
    color: #fff;
    color: #fff;
    text-align: center;
    font-family: Urbanist;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
}

.list_task_user_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list_task_avatar {
    width: 50px;
    height: 50px;
}

.list_task_name {
    margin-left: 14px;
    color: #7c85a4;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
