.main_page_container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.three_el_container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.element {
    flex: 1 1;
    background-color: #FFFFFF;
    max-width: 100%;
    height: 550px;
    border-radius: 10px
}

.second_element {
    flex: 2 2;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 550px;
    margin: 0px 7.5px;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.fs_el {
    margin-right: 7.5px;
}

.th_el {
    margin-left: 7.5px;
}

.two_el_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 14px;
    margin-bottom: 24px;
}

.half_element {
    flex: 1;
    min-height: 200px;
    max-height: 657px;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.inner_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 43px 34px 0 34px;
    max-width: 100%;
}

.content_container {
    max-height: 520px;
    overflow: auto;
}

.content_container_small {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 420px;
    overflow: auto;
}


.add_user_container {
    display: flex;
    align-items: center;
    height: 71px;
    background: #F8F8F8;
    border-radius: 10px;
    cursor: pointer;
}

.add_icon {
    margin-left: 29px;
    margin-right: 24px;
}

.add_text_cont {
    display: flex;
    flex-direction: column;
}

.add_title {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #485171;
}

.add_subtitle {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9A9FB5;
}

.section_max > .name_section_container {
    display: none;
}

.statistic {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 99%;
    max-height: 400px;
}

.tasks_el {
    margin-left: 7.5px;
}

.dev_el {
    margin-right: 7.5px;
}

@media screen and (max-width: 1024px) {
    .three_el_container {
        flex-wrap: wrap-reverse;
    }

    .element {
        max-width: 492px;
    }

    .fs_el {
        margin: 0px;
        margin-right: 7.5px;
        order: -1;
    }

    .second_element {
        min-width: 280px;
        margin: 0px;
        margin-bottom: 15px;
    }

    .th_el {
        margin: 0px;
        margin-left: 7.5px;
        order: -1;
    }

}
@media screen and (max-width: 768px) {
    .three_el_container {
        flex-wrap: wrap-reverse;
    }

    .element {
        max-width: 364px;
    }

    .fs_el {
        margin: 0px;
        margin-right: 3px;
        order: -1;
    }

    .second_element {
        margin: 0px;
        margin-bottom: 7.5px;
    }

    .th_el {
        margin: 0px;
        margin-left: 3px;
        order: -1;
    }

    .two_el_container {
        flex-direction: column;
    }

    .tasks_el {
        margin: 15px 0px 0px 0px;
    }
    
    .dev_el {
        margin:0px;
    }

    

}

@media screen and (max-width: 425px){
    .element {
        max-width: 385px;
    }
    .fs_el {
        margin-right: 0;
        margin-top: 7.5px;
    }

    .th_el {
        max-width: 385px;
        margin: 7.5px 0px;
    }
}

@media screen and (max-width: 375px){
    .fs_el {
        margin-right: 0;
        margin-top: 7.5px;
    }

    .th_el {
        margin: 7.5px 0px;
    }
}

@media screen and (max-width: 320px){
    .second_element {
        max-width: 300px;
        margin: 0px;
        margin-bottom: 15px;
    }
    

    .th_el {
        margin: 0px;
        max-width: 280px;
    }
    
    .fs_el {
        margin-right: 0;
        margin-top: 7.5px;
    }

    .add_user_container {
        padding-top: 5px;
        padding-bottom: 5px;
    }

}