.devices_log_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin-bottom: 12px;
    background-color: #F8F8F8;
    border-radius: 10px;
    text-decoration: none;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #7C85A4;
}

.devices_log_cont_first {
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr) 3fr 0.6fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    height: 77px;
    width: 100%;
    margin-bottom: 12px;
    background-color: #F8F8F8;
    border-radius: 10px;
    text-decoration: none;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #7C85A4;
    cursor: pointer;
}

.devices_log_cont_first div:last-child{
    display: flex;
    justify-content: end;
}

.log_health {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 267px;
    height: 70px;
}

.devices_log_img {
    margin-left: 26px;
}

.log_ago {
    color: #04CA7E;
}

.log_no_connect {
    color: #F36D6B;
}

.claster_check_img {
    cursor: pointer;
}
/* 
@media screen and (max-width) {
    .devices_log_cont 
    
} */