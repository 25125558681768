.name_section_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 52px;
}

.name_section_container>h2 {
    margin: 0;
    font-style: normal;
    font-family: 'Urbanist';
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #485171;
}

.name_section_container>h3 {
    margin: 0px;
    margin-top: 3px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9FB5;
}

.temporary_shevron {
    border: 1px solid #04ca7e;
    border-radius: 12px;
    padding: 2px 5px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #04ca7e;
}