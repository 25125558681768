.log_comp_cont {
    margin: 20px;
    min-height: 77px;
    max-height: 400px;
    background-color: #F8F8F8;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.5s;
}

.log_min {
    max-height: 77px;
    transition: 0.5s;
}

.log_max {
    max-height: 306px;
    transition: 0.5s;
}

.log_name_text {
    margin-left: 17px;
}

.log_text_title {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #485171;
}

.log_text_desc {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #9A9FB5;
}

.log_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 32px 0 27px;
    height: 77px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.log_info_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.log_max .chevron {
    transform: rotate(180deg);
    transition: 0.5s;
}

.log_min .chevron {
    transition: 0.5s;
    cursor: pointer;
}


.log_min .log_fill{
    opacity: 0;
    transition: 0.5s;
}

.log_max .log_fill {
    opacity: 1;
    transition: 0.5s;
}

.log_fill {
    display: flex;
    flex-direction: row;
    margin: 23px 0px 23px 38px;
}

.log_fill > section {
    margin: 0px 18px 0px 87px;
    display: flex;
    flex-direction: row;
    flex: 1;
}

.log_fill > section > div {
    display: flex;
    flex-direction: column;
}

.log_first_inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
}

.log_inner_title {
    width: 45px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #485171;
}


.log_inner_desc {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #9A9FB5;

}

.area_title {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #485171;

}

.second_textarea {
    margin-top: 3px;
    resize: none;
    height: 155px;
    background: #EBEBEB;
    border-radius: 10px;
    border: none;
    padding: 5px 10px;
}

.second_textarea:focus {
    outline: none;
}

.log_fill_first {
    flex: 0;
}

.log_fill_second {
    flex: 1;
}

.log_fill_third {
    flex: 1;
    margin-left: 12px;
}
