.overview_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    max-width: 820px;
    height: 40px;
    margin-bottom: 28px;
}

.overview_info_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 13px;
}

.overview_name {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #485171;
}

.overview_mail {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9A9FB5;
}

.overview_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    max-width: 520px;
    height: 27px;
    margin-left: 10px;
    background: #F8F8F8;
    border-radius: 10px;
}

.overview_button_text {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #485171;
}


.overview_button:hover{
    cursor: pointer;
}

@media screen and (max-width: 320px) {
    .overview_container {
        flex-wrap: wrap;
        height: 90px;
        margin-bottom: 35px;
    }
    
    .overview_button {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }

    .overview_info_cont {
        margin-left: 40px;
    }
}