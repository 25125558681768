div.web-sh-term {
    width: 100%;
    height: 100%;
    background-color: #222222;
    display: flex;
    flex-direction: column;
    font-family: monospace;
    border-radius: 7px;
    overflow: auto;
    user-select: text;
}

div.web-sh-term::-moz-selection {
    background-color: #7a7a7a;
}

div.web-sh-term::selection {
    background-color: #7a7a7a;
}

div.web-sh-term-inner {
    padding: 10px;
}

section.wsht-row {
    width: 100%;
    color: #F5F5F5;
}

section.wsht-row > span {
    white-space: pre;
    display: flex;
}

section.wsht-row dir {
    margin: 0;
    padding: 0;
}

div.nowr {
    white-space: pre;
}

div.wsht-cursor {
    width: 1px;
    height: 100%;
    /* border: 1px solid #fff; */
    background-color: #fff;
    animation: blink 1s infinite;
    mix-blend-mode: difference;
}

.wesh-comp-hil {
    position: absolute;
    display: block;
    right: -40px;
    color: rgba(255, 255, 255, 0.3);
    width: 40px;
    text-align: left;
}

div.wsht-input-row {
    height: 15px;
    display: flex;
    flex-direction: row;
}

div.wsht-input-row > span {
    display: flex;
    flex-direction: row;
    position: relative;
}

.wesh-auto {
    gap: 5px;
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
}

@keyframes blink {
    0% {
        background-color: #fff;
    }
    50% {
        background-color: transparent;
    }
}