
.devices_page_container{
    width: 100%;
    height: 100%;
}



.devices_inner_container {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    margin-bottom: 100px;
    width: 100%;
    max-width: 100%;
    max-height: 854px;
    
}

.left_content_container {
    flex: 2;
    max-height: 854px;
    overflow: auto;
}

.right_content_container {
    flex: 1;
    max-height: 854px;
    max-width: 431px;
}

.devices_act_cont {
    display: flex;
    flex-direction: column;
    max-height: 630px;
    margin: 10px 0 20px 0;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
}

.dev_inner_container {
    margin: 0;
}

.name_section_cont {
    margin: 35px 36px 10px 33px;
}

.activity_dev_content_container {
    min-height: 250px;
    max-height: 530px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: auto;
}

.devices_page_header_cont {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 444px;
}
.devices_page_header_first {
    display: flex;
    flex-direction: row;
    gap:20px
}
@media screen and (max-width: 1100px) {
    .devices_inner_container {
        flex-direction: column;
        max-height: none;
    }
    .left_content_container {
        width: 100%;
    }
    .right_content_container {
        max-width: 100%;
    }
}
@media screen and (max-width: 607px) {
    .devices_page_header_cont {
        flex-direction: column;
    }
    .devices_page_header_first {
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 460px) {
    .devices_page_header_first {
        flex-direction: column;
    }
}