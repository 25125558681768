.save_button_cont {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 32px;
}
.save_button {
    display: flex;
    width: 150px;
    height: 45px;
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #04ca7e;
    border: none;
    color: #fff;
    font-size: 20px;
    font-family: 'Urbanist';
    font-weight: 600;
    cursor: pointer;
    transition-property: background;
    transition-duration: 0.2s;
}

.add_new_device_popup_container {
    padding: 37px;
}

.custom_select_area {
    display: flex;
    width: 100%;
    display: inline-flex;
    margin-top: 10.5px;
    padding: 17px 26px 17px 25px;
    justify-content: center;
    align-items: center;
    gap: 734px;
    border-radius: 10px;
    background: #f8f8f8;
    border: none;
    appearance: none;
    background-image: url('../../../../public/img/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    color: #7c85a4;
    font-size: 16px;
    font-family: "Urbanist";
    font-weight: 500;
    font-style: normal;
    line-height: 19px;
    cursor: pointer;
}

.custom_select_area:focus {
    outline: none;
}

.save_button:disabled {
    background: #83ebc3;
    cursor: default;
    transition-property: background;
    transition-duration: 0.2s;
}