.filled_skeleton_loader {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ededed 0%, rgba(124, 133, 164, 0.4) 25%, #ededed 50%);
    background-size: 100% 100%;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: skeletonAnimation;
    animation-timing-function: linear;
}

@keyframes skeletonAnimation {
    0% {
		background-position: -400px 0px;
	}
	100% {
		background-position: 400px 0px;
	}
}