.view_switcher_container {
    display: flex;
    flex-direction: row;
}
.view_switcher {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    cursor: pointer;
}

.first_switch {
    margin-right:14px;
}

.second_switch {
    margin-right:10px;
}
