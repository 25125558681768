.create_cluster_popup_cont {
    margin: 20px;
}

.create_cluster_button {
    display: flex;
    width: 200px;
    height: 45px;
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #04ca7e;
    border: none;
    color: #fff;
    font-size: 20px;
    font-family: 'Urbanist';
    font-weight: 600;
    cursor: pointer;
    transition-property: background;
    transition-duration: 0.2s;
}

.create_cluster_button:disabled {
    background: #83ebc3;
    cursor: default;
    transition-property: background;
    transition-duration: 0.2s;
}
