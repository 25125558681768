.bell_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 77px;
    border-right: 2px solid #F2F3F7;
    border-left: 2px solid #F2F3F7;
}

.bell{
    width: 23px;
    height: 23px;
}