.tasks_container {
    width: 100%;
    min-height: 77px;
    max-height: 450px;
    margin-bottom: 8px;
    background-color: #F8F8F8;
    border-radius: 10px;
    overflow: hidden;
}

.tasks_status_adaptive {
    display: none;
}

.tasks_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 55px);
    margin: 22px 0 0 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    cursor: pointer;
    transition: 0.5s;
}

.tasks_status {
    display: flex;
}



.tasks_min {
    height: 53px;
    transition: 0.5s;
}

.tasks_max {
    height: 260px;
    transition: 0.5s;
}

.tasks_min .chevron {
    transition: 0.5s;
}

.tasks_max .chevron {
    transform: rotate(180deg);
    transition: 0.5s;
}

.tasks_status {
    display: flex;
    align-items: center;
}

.tasks_status > div {
    margin-left: 3px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.in_work {
    color: #158AF0;
}

.expired {
    color: #F36D6B;
}

.done {
    color: #04CA7E;
}

.tasks_fill {
    margin: 17px 54px 0px 54px;
}

.tasks_fill_info {
    display: flex;
    margin-bottom: 8px;
}

.tasks_min .tasks_fill {
    opacity: 0;
    transition: 0.5s;
}

.tasks_min .tasks_button_container {
    margin: 0px 0 10px 50px;
    display: flex;
    flex-direction: row;
    opacity: 0;
    transition: 0.5s;
}

.tasks_fill_info_first {
    width: 72px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #485171;
}

.tasks_fill_info_second {
    display: flex;
    margin-left: 9px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #9A9FB5;
}

.linux_dev {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 19px;

    color: #FFFFFF;
    background: #158AF0;
    border-radius: 5px;
}

.windows_dev {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 19px;
    margin-left: 5px;
    color: #FFFFFF;
    background: #F36D6B;
    border-radius: 5px;
}

.tasks_button_container {
    display: flex;
    margin: 32px 54px 10px 54px;

}

.tasks_button_done {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    color: #FFFFFF;
    background: #04CA7E;
    border-radius: 6px;
}


.tasks_button_done>div {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-left: 6px;
}

.tasks_button_edit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    padding: 10px 15px;
    color: #485171;
    background: #D9D9D9;
    border-radius: 6px;
}

.tasks_button_edit>div {
    margin-left: 6px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #485171;
}

.tasks_button_done:hover {
    cursor:pointer;
}
.tasks_button_edit:hover {
    cursor:pointer;
}

.tasks_min .tasks_fill {
    opacity: 0;
    transition: 0.5s;
}
.tasks_max .tasks_fill {
    opacity: 1;
    transition: 0.5s;
}

.tasks_time {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
}

.tasks_time img {
    margin-right: 3px;
}

@media screen and (max-width: 1024px) {
    .tasks_min {
        height: 90px;
        transition: 0.5s;
    }
    
    .tasks_max {
        height: 300px;
        transition: 0.5s;
    }

    .tasks_time {
        flex-direction: column;
        margin: 0;
    }

    .tasks_time img {
        width: 34px;
        height: 14px;
    }

    .tasks_time div {
        width: 55px;
    }
}

@media screen and (max-width: 768px) {
    .tasks_time {
        flex-direction: row;
        margin: 0;
    }

    .tasks_time img {
        width: 20px;
        height: 14px;
    }

    .tasks_min {
        height: 52px;
        transition: 0.5s;
    }
    
    .tasks_max {
        height: 260px;
        transition: 0.5s;
    }
}

@media screen and (max-width: 425px) {
    .tasks_min {
        height: 100px;
        transition: 0.5s;
    }
    
    .tasks_max {
        height: 300px;
        transition: 0.5s;
    }

    .tasks_info {
        margin: 25px 5px 0 0;
        width: calc(100% - 20px);
    }

    .tasks_time {
        display: none;
    }

    .tasks_min .tasks_button_container {
        margin: 30px 30px;
    }

    .tasks_status_adaptive {
        display: flex;
        align-items: center;
    }
    
    .tasks_status_adaptive >div {
        margin-left: 3px;
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
    }

    .tasks_fill {
        margin: 10px 15px ;
    }

    .tasks_button_container {
        margin: 30px 30px;
    }

}

@media screen and (max-width: 375px) {

    .tasks_max {
        height: 400px;
    }

    .tasks_fill {
        margin: 12px 0 0 0;
    }

    .tasks_fill_info {
        flex-direction: column;
        margin-left: 20px;
        margin-bottom: 15px;
    }

    .tasks_fill_info > img {
        width: 21px;
        height: 20px;
    }

    .tasks_fill_info_second {
        margin-top: -20px;
        margin-left: 30px;
    }

    .tasks_devices {
        margin: 0;
        max-width: 135px;
    }

    .tasks_button_container {
        margin-left: 0 auto;
        flex-direction: column;
    }

    .tasks_min .tasks_button_container {
        margin-left: 0 auto;
        flex-direction: column;
    }

    .tasks_button_done {
        padding: 10px 20px;
    }

    .tasks_button_edit {
        margin: 0;
        margin-top: 5px;
        padding: 10px 20px;
    }
}

@media screen and (max-width: 320px) {
    .tasks_min {
        height: 160px;
    }
    
    .tasks_max {
        height: 450px;
    }

    .tasks_button_container {
        margin: 0 auto;
        margin-left: 30px;
    }
    
    .tasks_min .tasks_button_container {
        margin: 0 auto;
        margin-left: 30px;
    }

    .tasks_button_done {
        width: 100px;
    }

    .tasks_button_edit {
        width: 100px;
    }

}