.sign_cont > div {
    color: #9A9FB5;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.link_container > button {
    margin: 0px 10px;
    background-color: #fff;
    border: none;
}

.forgot_container > button {
    float: right;
    border: none;
    background-color:#fff;
    color: #9A9FB5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    
}

.forgot_container > button:hover {
    cursor: pointer;
}

.signup_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup_button_text > .signup_link:hover {
    cursor: pointer;
}

.button_login_org {
    padding: 0px 6px;
    background-color: #158AF0;
    width: 361px;
    height: 45px;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 320px) {
    .section_cont {
        width: 280px;
    }
}