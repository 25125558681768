.tasks_main_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tasks_button_header {
    display: flex;
    flex-direction: row;
}

.tasks_main_cont {
    margin-top: 20px;
    padding: 36px 30px 30px 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.tasks_main_title {
    margin-bottom: 33px;
    color: #485171;
    font-family: Urbanist;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
