.nodevices_container {
    width: 100%;
    min-height: 250px;
    max-height: 450px;
    margin-bottom: 8px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #F8F8F8;
}

.no_activity_cont {
    display: flex;
    align-items: center;
    justify-content:center;
    min-height: 250px;
    max-height: 460px;
    margin-top:20px;
}

.no_activity_text {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 22px;
    color: #485171;
}