.perm-err-cont {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    gap:10px;
    border: 1px solid #F36D6B;
    border-radius: 13px;
    background: #FFFFFF;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #485171;
}

