#__modal__ {
    position: fixed;
    top: 5px;
    right: 50%;
    transform: translate(50%, 0%);
    padding: 10px;
    z-index: 20000;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-container {
    display: flex;
    flex-direction: row;
    border-radius: 24px 24px 24px 24px;
    margin-bottom: 5px;
    animation: showHide 7s ease-in-out;
    color: #9A9FB5;
    background-color: rgb(255, 255, 255);
    width: 700px;
}

.modal-content {
    flex: 1;
    padding: 12px;
}

.modal-image-container {
    width: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal-image-container > img {
    width: 40px;
}

.error-modal-container > .modal-image-container {
    border-radius: 24px 0px 0px 24px;
    background-color: #F36D6B;
}

.alert-modal-container > .modal-image-container {
    border-radius: 24px 0px 0px 24px;
    background-color: #ffd542;
}

.success-modal-container > .modal-image-container {
    border-radius: 24px 0px 0px 24px;
    background-color: #04CA7E;
}

.modal-head {
    margin: 0 0 4px 0;
    font-family: 'Urbanist';
    font-size: 20px;
    font-weight: 900;
    line-height: 23.76px;
    letter-spacing: 0.04em;
    color: #485171;
}

.modal-body {
    font-family: 'Urbanist';
    font-weight: 700;
    font-size: 14px;
    line-height: 20.16px;
    letter-spacing: 0.05em;
    margin: 0;
    color: var(--font-main-color);
}

.modal-remove-btn {
    background: transparent;
    border: none;
    user-select: none;
}

.modal-remove-btn:hover {
    cursor: pointer;
}

.modal-remove-btn > img {
    width: 15px;
    margin-right: 15px;
    margin-left: 5px;
}

@keyframes showHide {
    0% {
        opacity: 0;
    } 8% {
        opacity: 1;
    } 92% {
        opacity: 1;
    } 100% {
        opacity: 0;
    }
}

@media screen and (max-width: 750px) {
    .modal-container {
        width: 92.5%;
    }
}