.sign_cont>div {
    color: #9A9FB5;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

#form > .login_organization_link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    color: #485171;
    width: 361px;
    height: 45px;
    margin-bottom: 10px;
    margin-top: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.link_container > button {
    margin: 0px 10px;
    background-color: #fff;
    border: none;
}

.forgot_container {
    margin-top: -14px;
    margin-bottom: 24px;
}

.forgot_container > button {
    float: right;
    border: none;
    background-color: #fff;
    color: #9A9FB5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;

}

.forgot_container>button:hover {
    cursor: pointer;
}

.signup_button_text {
    color: #9A9FB5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
}

.signup_button_text > .signup_link {
    color: #9A9FB5;
    text-decoration: underline;
}

.signup_button_text>.signup_link:hover {
    cursor: pointer;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #9A9FB5;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #9A9FB5;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #9A9FB5;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #9A9FB5;
}


@media screen and (max-width: 375px) {
    form>.login_organization_link {
        width: 300px;
    }

}

@media screen and (max-width: 320px) {
    form>.login_organization_link {
        width: 280px;
    }

}