.search_area {
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 300px;
    height: 50px;
}

.search_area > input {
    padding: 0;
    height: 50px;
}

.search_img {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
}

.search_input {
    height: 45px;
    border: none;
    width: 250px;
    border-radius: 15px;
}

.search_input:focus {
    outline: none !important;
}
