.about_comm_cont {
    margin-top: 28px;
}

.comm_text_row_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 28px;
    margin-bottom: 6px;
}

.comm_text_title {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #485171;
}

.comm_text_descr {
    margin-left: 5px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(154, 159, 181, 1);
}

.command_area {
    display: flex;
    flex-direction: column;
    margin: 0 28px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #485171;
}

.textarea_comm {
    height: 79px;
    resize: none;
    margin-top: 6px;
    padding: 16px;
    background: #F8F8F8;
    border-radius: 10px;
    border: 0;
    font-size: 14px;
    color: #485171;
}

.textarea_comm:focus {
    outline: none
}

.outline_area {
    display: flex;
    flex-direction: column;
    margin: 0 28px;
    margin-top: 6px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #485171;
}

.textarea_outline {
    height: 233px;
    resize: none;
    margin-top: 6px;
    padding: 16px;
    background: #F8F8F8;
    border-radius: 10px;
    border: 0;
    overflow: auto;
    margin-bottom: 39px;
    font-size: 14px;
    color: #485171;
}

.textarea_outline:focus {
    outline: none
}

.trans_cop {
    cursor: pointer;
}