aside.freelance-menu {
    display: none;
}

.freelance-menuhd {
    margin-left: 20px;
}

.freelance-menucont {
    justify-content: center;
}